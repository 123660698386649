import React, { memo, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from 'grommet';

import { get } from '../../lib/nodash';
import { useStoryblokState } from '../../lib/storyblok';
import Seo from '../../components/Seo';
import usePageBodyComponents from '../../components/DynamicPage/usePageBodyComponents';
import selectJwt from '../../state/industry/selectJwt';

const MyAccount = ({ data, location }) => {
  const { storyblokEntry } = data;
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname;
  const story = useStoryblokState(storyblokEntry);
  const components = usePageBodyComponents(story);
  const jwt = useSelector(selectJwt);
  const authChecked = useSelector((state) => state.industry.authChecked);

  useEffect(() => {
    if (authChecked && !jwt) {
      navigate('/members/login');
    }
  }, [jwt, authChecked]);

  return (
    <>
      <Seo
        title={
          get('content.metafields.title', story) ||
          get('title', story) ||
          get('field_title_string', storyblokEntry)
        }
        description={get('content.metafields.description', story)}
        image={get('content.seoImage.filename', story)}
        canonical={canonicalUrl}
      />
      <Box>{components}</Box>
    </>
  );
};

MyAccount.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default memo(MyAccount);

export const query = graphql`
  query MyAccountContent {
    storyblokEntry(full_slug: { eq: "pages/my-account" }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
